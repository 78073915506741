import api from "../api";
import ReactGA from "react-ga";

import {
  ADD_ITEMS,
  SET_EVAL_BY_TOPICS,
  SET_FIXED_FILTERS,
  SET_KEYWORD,
  SET_META_INFO,
  SET_PAGINATION,
  SET_QUOTES,
  SET_REQUEST_STATUS,
  SET_TOPIC_FILTER
} from "./types";

export function addItems(payload) {
  return {
    type: ADD_ITEMS,
    payload
  };
}
let personsMap;

export function setFilters(payload) {
  try {
    // Send GoogleAnalytics
    const { key, value, title } = payload;
    if (value && !(key === "personFilter" && value.isActive === false)) {
      let name;
      if (key === "personFilter" && personsMap && personsMap[value.id]) {
        name = personsMap[value.id].name;
      }
      const usersEvent = {
        category: key,
        action: title || (value && (name || value.id))
      };
      ReactGA.event(usersEvent);
    }
  } catch (e) {
    console.error('actions GA: ', e.message);
  }

  return {
    type: SET_TOPIC_FILTER,
    payload
  };
}

export function setFilterFixed(payload) {
  return {
    type: SET_FIXED_FILTERS,
    payload
  };
}

export function setPersonsInitial(payload) {
  personsMap = payload.personsDict;
  const validOptions = {
    topic: payload.topicsList.map(d => d.title)
  };
  return {
    type: SET_META_INFO,
    payload: {...payload, validOptions}
  };
}

export function setPagination(payload) {
  return {
    type: SET_PAGINATION,
    payload
  };
}

export function setEvalByTopics(payload) {
  return {
    type: SET_EVAL_BY_TOPICS,
    payload
  };
}

export function setQuotes(payload) {
  return {
    type: SET_QUOTES,
    payload
  };
}

export function setKeyword(payload) {
  return {
    type: SET_KEYWORD,
    payload
  };
}

export function setRequestStatus(payload) {
  return {
    type: SET_REQUEST_STATUS,
    payload
  };
}

export function searchAction(keyword) {
  return dispatch => {
    const requestKey = "searchFilms";
    dispatch(setKeyword(keyword));
    dispatch(
      setRequestStatus({
        status: "pending",
        key: requestKey
      })
    );
    return api
      .fetchList(keyword)
      .then(response => {
        dispatch(addItems(response.data));
        dispatch(
          setRequestStatus({
            status: "success",
            key: requestKey
          })
        );
      })
      .catch(error => {
        dispatch(
          setRequestStatus({
            status: "error",
            key: requestKey
          })
        );
        throw error;
      });
  };
}

export function initialSearchAction(keyword) {
  return dispatch => {
    const requestKey = "initialRequest";
    dispatch(
      setRequestStatus({
        status: "pending",
        key: requestKey
      })
    );
    return api
      .initialRequest()
      .then(({ data }) => {
        dispatch(setPersonsInitial(data));
        dispatch(
          setRequestStatus({
            status: "success",
            key: requestKey
          })
        );
        // dispatch(getEvalByTopicAction());
      })
      .catch(error => {
        dispatch(
          setRequestStatus({
            status: "error",
            key: requestKey
          })
        );
        throw error;
      });
  };
}

function getEvalByTopicAction() {
  return dispatch => {
    const requestKey = "evalByTopicsRequest";
    dispatch(
      setRequestStatus({
        status: "pending",
        key: requestKey
      })
    );
    return api
      .evalByTopicRequest()
      .then(({ data }) => {
        dispatch(setEvalByTopics(data));
        dispatch(
          setRequestStatus({
            status: "success",
            key: requestKey
          })
        );
      })
      .catch(error => {
        dispatch(
          setRequestStatus({
            status: "error",
            key: requestKey
          })
        );
        throw error;
      });
  };
}

export function getQuotesAction(keyword) {
  return dispatch => {
    const requestKey = "getQuotes";
    keyword && dispatch(setKeyword(keyword));
    dispatch(
      setRequestStatus({
        status: "pending",
        key: requestKey
      })
    );
    return api
      .getQuotes()
      .then(({ data }) => {
        dispatch(setQuotes(data));
        dispatch(
          setRequestStatus({
            status: "success",
            key: requestKey
          })
        );
      })
      .catch(error => {
        dispatch(
          setRequestStatus({
            status: "error",
            key: requestKey
          })
        );
        throw error;
      });
  };
}
