import React from 'react';
import styled from "styled-components";

const Wrapper$ = styled.div`
  position: relative;
  
  img {
    cursor: pointer;
  }
`;

const Overlay$ = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
`;
const Menu$ = styled.div`
  position: absolute;
  top: 0;
  left: 20px;
  padding: 20px;
  padding-bottom: 10px;
  background: #FFFFFF;
  border: 1px solid #F0F0F0;
  box-sizing: border-box;
  border-radius: 3px;
  line-height: 1.5;
  z-index: 10;
  
  a { 
    display: block;
    margin-bottom: 10px;
    font-size: 12px !important;
    text-decoration: none;
  }
`;

export class HeaderMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }
  onMenuClick = () => {

  };
  render() {
    return (
      <Wrapper$>
        <div onClick={() => this.setState({expanded: !this.state.expanded})}>
          <img src='icons/menu.svg'/>
        </div>
        {this.state.expanded && this.props.items && (
          <Overlay$ onClick={()=>this.setState({expanded: false})}>
            <Menu$>
              {this.props.items}
              {this.props.buttons}
            </Menu$>
          </Overlay$>
        )}
      </Wrapper$>
    )
  }
}
