/* TYPES */
export const ADD_ITEMS = "ADD_ITEMS";
export const SET_KEYWORD = "SET_KEYWORD";
export const SET_META_INFO = "SET_META_INFO";
export const SET_QUOTES = "SET_QUOTES";
export const SET_TOPIC_FILTER = "SET_TOPIC_FILTER";
export const SET_REQUEST_STATUS = "SET_REQUEST_STATUS";
export const SET_EVAL_BY_TOPICS = "SET_EVAL_BY_TOPICS";
export const SET_PAGINATION = "SET_PAGINATION";
export const SET_FIXED_FILTERS = "SET_FIXED_FILTERS";
