import React from "react";
import styled from "styled-components";
import { evalColorMap, linksColor, quotesInScreen } from "../constants";
import { media } from "../helpers";
import sanitizeHtml from "sanitize-html";
import ReactPaginate from "react-paginate";

const FlexRow$ = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const Wrapper$ = styled(FlexRow$)`
  background: #FFFFFF;
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 10px 0;
  padding: 13px 20px;
  box-sizing: border-box;
  position: relative;
  font-size: 12px;
  
  a {
    text-decoration-line: underline;
    color: ${linksColor};
  }
`;

const QuoteWrapper$ = styled.div`
  color: #2a2a2a;
  width: 60%;

  ${media.phone`
    width: 100%;
  `};
`;
const Quote$ = styled.div`
  font-weight: 500;
  margin: 6px 0;
  font-size: 14px;
`;

const CommentWrapper$ = styled.div`
  display: flex;
  flex-direction: column;

  ${p => p.expanded ? `
  position: absolute; 
  right: 20px;
  top: 13px;
  bottom: 13px;
  width: calc(38% - 15px);` : `
  
  position: relative; 
  right: 0px;
  top: 0;
  bottom: 0;
  width: 38%; 
  `}  
  padding: 0 17px 25px 17px ;
  border-radius: 5px;
  
  ${props => `background: linear-gradient(180deg, #fff 27.74%, ${evalColorMap[props.evaluation]}a1 106.82%);`}
  
  ${media.phone`
    width: 100%;
    position: relative;
    right: 0px;
    top: 0;
    bottom: 0;
    margin-top: 10px;
  `};
`;

const CommentMask$ = styled.div`
  overflow: hidden;
  marin-bottom: 20px;
`;

const Comment$ = styled.div`
  position: relative;
  color: #585852;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  
  // Styles for comments html
  p {
    margin: 0;
  }
  
  ${media.phone`
    max-height: ${props => (props.expanded ? '50px' : 'inherit')};
  `};
`;

const ExpandBtn$ = styled.div`
  cursor: pointer;
  font-weight: bold;
  color: #FFF;
  font-size: 20px;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  right: 0;
  padding: 0 17px;
  
  ${p => !p.expanded && `
    img {
      transform: rotate(180deg)
    }
  `}
`;

const Tag$ = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #444;
  border: 1px solid #F0F0F0;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0 5px;
  height: 23px;
  margin: 9px 5px 0 0 ;
`;

const Eval$ = styled.span`
  color: ${props => evalColorMap[props.evaluation]};
  font-weight: bold;
  text-transform: uppercase;
  margin: 7px 0 15px 0;
`;

const Centered$ = styled.div`
  text-align: center;
  margin: 15px 0 40px 0;
  
  ul {
      padding: 0;
  }
  
  li {
    display: inline-block;
    margin: 10px;
    cursor: pointer;
    font-size: 10px;
    text-align: center
    
    &.selected {
      box-sizing: border-box;
      background-color: #444;
      padding-top: 2px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      color: white;
    }
    a:focus {
      outline: none;
    }
   }
`;

const Name$ = styled.div`
  font-weight: 600;
`;

class Quote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true
    };
  }

  render() {
    const {
      personsDict,
      data: {
        person_id,
        evaluation,
        quote,
        quote_id,
        quote_date,
        comment,
        source,
        topic1,
        topic2
      }
    } = this.props;
    if (!person_id) return null;
    const { expanded } = this.state;
    let wrappedSource = (~source.indexOf('<a')) ?
      source.replace('<a', '<a target="blank"')
      : `<a href=${source} target="blank">Джерело</a>`;
    const isShortComment = quote.length > comment.length;
    return (
      <Wrapper$ evaluation={evaluation}>
        <QuoteWrapper$ id={quote_id}>
          <div>
            <Name$>{personsDict[person_id].name}</Name$>
            <div>{quote_date}</div>
          </div>
          <Quote$>
            “
            {quote}”
          </Quote$>
          <span dangerouslySetInnerHTML={{__html: sanitizeHtml(wrappedSource)}}/>
          <div>
            <Tag$>{topic1}</Tag$>
            {topic2 && <Tag$>{topic2}</Tag$>}
          </div>
        </QuoteWrapper$>
        <CommentWrapper$ expanded={expanded} evaluation={evaluation}>
          <Eval$ evaluation={evaluation}>{evaluation}</Eval$>
          <CommentMask$ onClick={() => this.setState({ expanded: !expanded })}>
            <Comment$ expanded={expanded} dangerouslySetInnerHTML={{__html: sanitizeHtml(comment)}}/>
          </CommentMask$>
          { !isShortComment && (
            <ExpandBtn$ onClick={() => this.setState({ expanded: !expanded })} expanded={expanded}>
              <img src='icons/arrow-white-up.svg' height="10"/>
            </ExpandBtn$>
          )}
        </CommentWrapper$>
      </Wrapper$>
    );
  }
}

export const QuotesList = props => {
  const {
    filteredQuotes,
    personsDict,
    setPagination,
    pagination,
    isMobile
  } = props;
  const paginate = (arg) => {
    setPagination(arg);
    window.scrollTo(0,300);
  };
  const sliceStartIndex = (pagination-1) * quotesInScreen;
  const amountOfPages = Math.ceil(filteredQuotes.length/quotesInScreen);
  return (
    <div>
      {filteredQuotes
        .slice(sliceStartIndex, sliceStartIndex + quotesInScreen)
        .map(quote =>
          <Quote
            key={quote.quote_id}
            data={quote}
            personsDict={personsDict}
          />
        )}
      {filteredQuotes.length > quotesInScreen && (
        <Centered$>
          <ReactPaginate
            previousLabel={pagination === 1 ? '': isMobile ? '<': 'Попередні'}
            nextLabel={pagination === amountOfPages ? '': isMobile ? '>': 'Наступні'}
            breakLabel={'...'}
            pageCount={amountOfPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={isMobile ? 3 : 5}
            onPageChange={paginate}
            initialPage={pagination-1}
            disableInitialCallback={true}
          />
          <button onClick={()=> window.scrollTo(0, 300)}>Вгору</button>
        </Centered$>
      )}
    </div>
  );
};

