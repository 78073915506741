import React from "react";
import { Header } from "./components/Header";
import MainPage from "./components/MainPage.container";
import { Route, Switch } from "react-router-dom";
import Metodology from "./Metodology";
import About from "./About";

const App = () => (
  <>
    <Header/>
    <main>
      <Switch>
        <Route exact path="/" component={MainPage}/>
        <Route exact path="/methodology" component={Metodology}/>
        <Route exact path="/about" component={About}/>
      </Switch>
    </main>
  </>
);

export default App;
