import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { media } from "../helpers";
import { Barchart } from "./Barchart";

const CardWrapper$ = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  position: relative;
  min-height: 120px;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  flex-wrap: wrap;
`;

const ContentWrapper$ = styled.div`
  min-width: 250px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  
  ${media.phone`
    width: 100%;
  `};
`;

const MetaWrapper$ = styled.div`
  
`;

const Title$ = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 8px;
`;

const BarchartWrapper$ = styled.div`
  flex-grow: 1;
  margin: 0 0 0 15px;
  ${media.phone`
    width: 100%;
    margin: 15px 0 0 0;
  `}
`;

const BarTitle$ = styled(Title$)`
  margin: 0 0 8px 100px;
`;

const ImgWrapper$ = styled.div`
  margin-right: 15px;
  ${media.tablet`
    // display: none;
    // margin-bottom: 50px
  `}

`;

const Img$ = styled.img`
  height: 90px;
  width: 90px;
  background-color: white;
  object-fit: cover;
  border-radius: 50%;
  position: relative;gi
  
  &:after {
    content: attr(alt);  
    display: flex;
    font-size: 12px;
    font-style: italic;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(nophoto.jpeg) no-repeat center;
    background-size: contain;
   
  }
`;

const Description$ = styled.div`
  font-size: 12px;
`;

const ResetBtn = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;
  padding: 10px;
  cursor: pointer;  
`;

export const PersonsCard = ({
                              item: { name, position, statistics, person_id }, setFilters
                            }) => (
  <CardWrapper$>
    <ResetBtn onClick={e => setFilters({
      key: "personFilter", value: {
        id: person_id,
        isActive: false
      }
    })}>
      <img src="icons/close.svg"/>
    </ResetBtn>
    <ContentWrapper$>
      <ImgWrapper$>
        <Img$ src={`photo/${person_id}.jpg`}/>
      </ImgWrapper$>
      <MetaWrapper$>
        <Title$>{name}</Title$>
        <Description$>{position}</Description$>
      </MetaWrapper$>
    </ContentWrapper$>
    <BarchartWrapper$>
      <BarTitle$>Перевірених висловлювань | {statistics.total}</BarTitle$>
      <Barchart
        data={statistics.evaluation}
      />
    </BarchartWrapper$>
  </CardWrapper$>
);

PersonsCard.propTypes = {
  item: PropTypes.object.isRequired,
  analyticsHandler: PropTypes.func
};

export const CommonCard = ({ commonStatistics }) => (
  <CardWrapper$>
    <BarchartWrapper$>
      <BarTitle$>Загальна статистика</BarTitle$>
      <Barchart
        data={commonStatistics.evaluation}
      />
    </BarchartWrapper$>
  </CardWrapper$>
);
