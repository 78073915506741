import { css } from "styled-components";
import { screenSizes } from "./constants";
import get from 'lodash/get';

/* Styled-components media- alias */

// Iterate through the sizes and create a media template
export const media = Object.keys(screenSizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${screenSizes[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

export const mapToArr = map => Object.keys(map).map(key => map[key]);
export const sortPersonsByEval = (persons = []) =>
  [...persons].sort(
    (a, b) =>
      get(a, 'statistics.evaluation[3].value', 0) -
      get(b, 'statistics.evaluation[3].value', 0)
  );
export const sortPersonsByName = (persons = []) =>
  [...persons].sort((a, b) =>
    a.name.split(" ")[1].localeCompare(b.name.split(" ")[1])
  );
export const getQuery = (str = "") => {
  if (str.length < 3) return {}; // 3: a = 1 - at least 3 symbols
  const variablesStr = str.replace("?", "").split("&");
  return variablesStr.reduce((res, vStr) => {
    const [key, value] = vStr.split("=");
    res[key] = decodeURI(value);
    return res;
  }, {});
};

export const stringifyQuery = (map) => {
  const newQueryStr = Object.keys(map).reduce((res, key, i) => ((map[key] && map[key].length)
    ? `${res}${i ? "&" : ""}${key}=${map[key]}` : res), "?");
  return newQueryStr.length > 1 ? newQueryStr : "";
};

export const getTrueKeys = (map = {}) => (
  Object.keys(map).reduce((res, key) => (map[key] ? [...res, key] : res), [])
);

export const parseTrueKeys = (str = "", validOptions) => (
  str.split(",").reduce((res, uriKey) => {
    const key = decodeURI(uriKey);
    return (!validOptions || validOptions.indexOf(decodeURI(key)) !== -1) ?
      { ...res, [key]: true } : res;
  }, {})
);
