import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store/configureStore";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import ReactGA from "react-ga";
import { googleAnalyticsId } from "./constants";

ReactGA.initialize(googleAnalyticsId);
ReactGA.pageview(window.location.pathname + window.location.search);

const store = configureStore();

const renderApp = () =>
  ReactDOM.render(
    <Provider store={store}>
      <HashRouter>
        <App />
      </HashRouter>
    </Provider>,
    document.getElementById('root')
  );

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./App', () => {
    renderApp();
  });
}

renderApp();

serviceWorker.unregister();
