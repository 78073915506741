import React from "react";
import styled from "styled-components";
import { media } from "./helpers";
import { telegramLink } from "./constants";

const Wrapper$ = styled.div`
    margin: 40px 0;
   ${media.verticalPhone`
      padding: 0 20px;
    `};
`;
const Empty$ = styled.div`
   flex-grow: 1
`;
const PhotoWrapper = styled.div`
   display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
  img {
    margin-bottom: 15px;
  }
`;

const Photo = styled.div`
   display: flex;
   flex-direction: column
  justify-content: space-between;
  width: 125px;
  height: 200px;
  margin: 10px;
`;
export default () => (
  <Wrapper$>
    <b>Навіщо потрібна Антологія брехні і як нею користуватися</b>

    <p>Ця база є першою в Україні спробою системно показати брехню та маніпуляції політиків на важливі для суспільства
      теми. Базу можна фільтрувати, читати та поширювати у соцмережах*. Також ви можете шукати за словами та фразами:
      введіть у відповідне віконце, наприклад, слово “лангуст" та перегляньте результат. Наша ціль у цьому проекті - не
      тільки притягнути українських політиків до відповідальності, а й допомогти українцям розібратися у деяких складних
      темах, про які політики часто кажуть у медіа.</p>

    Вперше “Антологія брехні" була викладена у відкритий доступ у лютому 2019 року напередодні виборів Президента
    України. Відтоді база регулярно поповнюється новими висловлюваннями політиків та темами. За оновленнями та
    фактчек-інсайдами слідкуйте у <a href={telegramLink}>Телеграм-каналі “Антології брехні"</a>.

    <p><b>Хто зробив Антологію брехні</b></p>

    Ця база створена проектом <a href="https://voxukraine.org/uk/voxcheck/">VoxCheck</a>. Це фактчекінговий проект
    громадської організації <a href="https://voxukraine.org/uk">VoxUkraine</a>, який працює з початку 2016 року. Проект
    перевіряє висловлювання українських політиків на економічну, законодавчу, соціальну та інші тематики, які містять
    дані та факти. З 23 березня 2018 року VoxCheck є підписантом <a
    href="https://ifcncodeofprinciples.poynter.org/signatories">Кодексу етики Міжнародної мережі фактчекерів інституту
    Poynter</a>.

    <p>Восени 2017 року VoxCheck розробив пробний <a href="https://voxukraine.org/longreads/lie-theory/index-ua.html">“Рейтинг
      маніпуляторів та брехунів"</a> серед українських політиків. З січня 2018 ми стали щоденно слідкувати за
      висловлюваннями лідерів соціологічних опитувань та перевіряти їхні висловлювання. Протягом року проект перевірив
      більше 1900 тверджень українських політиків та опублікував річний звіт <a
        href="https://voxukraine.org/longreads/lie-2018/index.html">“Рейтинг маніпуляторів та брехунів за 2018 рік"</a>.
      Всі ці фактчеки були незабаром опубліковані в “Антології брехні". Методологію збору та аналізу тверджень політиків
      читайте за цим посиланням. </p>

    Станом на початок 2019 року над проектом VoxCheck працювали сім штатних аналітиків та шість членів редколегії
    VoxUkraine, а також волонтери. Саме ці люди спільними зусиллями створили <a href="https://nepravda.org">“Антологію
    брехні"</a>:

    <p><b>Аналітики VoxCheck</b></p>
    <PhotoWrapper>
      <Photo><img src="photo/vox/shkarpova.jpg"
                  width="125"/><span>Олена Шкарпова, Медіа директор VoxUkraine, редактор</span><Empty$/></Photo>
      <Photo><img src="photo/vox/skubenko.jpg"
                  width="125"/><span>Максим Скубенко, Заступник редактора</span><Empty$/></Photo>
      <Photo><img src="photo/vox/krymeniuk.jpg" width="125"/><span>Олексій Крименюк, Аналітик</span><Empty$/></Photo>
      <Photo><img src="photo/vox/slipchenko.jpg" width="125"/><span>Світлана Сліпченко, Аналітик</span><Empty$/></Photo>
      <Photo><img src="photo/vox/batoh.jpg" width="125"/><span>Яр Батог, Аналітик</span><Empty$/></Photo>
      <Photo><img src="photo/vox/moshnyaha.jpg"
                  width="125"/><span>Назар Мошняга, Молодший аналітик</span><Empty$/></Photo>
      <Photo><img src="photo/vox/landa.jpg"
                  width="125"/><span>Володимир Ланда, Науковий редактор</span><Empty$/></Photo>
    </PhotoWrapper>
    <b/>
    <p><b>Редколегія VoxCheck</b></p>
    <PhotoWrapper>
      <Photo><img src="https://voxukraine.org/wp-content/uploads/2016/12/sologoub.png" alt="ilona" width="125"/><span>Ілона Сологуб, СЕО VoxUkraine, економіст Київської школи економіки</span><Empty$/></Photo>
      <Photo><img src="https://voxukraine.org/wp-content/uploads/2016/12/zholyd1.png" alt="Zholud" width="125"/><span>Олександр Жолудь, Національний банк України</span><Empty$/></Photo>
      <Photo><img src="https://voxukraine.org/wp-content/uploads/2016/12/movchan.png" alt="veronika" width="125"/><span>Вероніка Мовчан, Інститут економічних досліджень та політичних консультацій</span><Empty$/></Photo>
      <Photo><img src="https://voxukraine.org/wp-content/uploads/2016/11/betliy.png" alt="betliy" width="125"/><span>Олександра Бетлій, Міністерство фінансів України</span><Empty$/></Photo>
      <Photo><img src="https://voxukraine.org/wp-content/uploads/2017/06/repko.png" alt="" width="125"/><span>Марія Репко, Центр економічної стратегії</span><Empty$/></Photo>
      <Photo><img src="https://voxukraine.org/wp-content/uploads/2018/03/dmytro.png" alt="" width="125"/><span>Дмитро Яблоновський, Центр економічної стратегії</span><Empty$/></Photo>
    </PhotoWrapper>
    <p><b>Фінансування проекту “Антологія брехні"</b></p>

    Проект VoxCheck фінансується за кошт українських громадян та міжнародних донорів. У 2016 та 2017-2018
    роках VoxCheck провів дві краудфандингові кампанії на платформі “Спільнокошт" (ось <a
    href="https://biggggidea.com/project/voxcheck/">перша</a>, <a
    href="https://biggggidea.com/project/voxcheck-vybory-blizko/">друга</a>). Друга кампанія проводилася
    для розробки <a href="https://voxukraine.org/longreads/lie-2018/index.html">Рейтингу маніпуляторів та
    брехунів</a>, а також створення Антології брехні.

    <p>Протягом двох кампаній проект отримав <b>794 внески</b> на загальну суму <b>955 372 грн</b>. Також
      проект підтримують міжнародні інституційні донори National Endowment for Democracy, PACT, TTF,
      Міжнародний фонд “Відродження" та інші.</p>

    Ви також можете підтримати проект “Антологія брехні" своїм внеском на будь-яку суму за <a
    href="https://www.liqpay.ua/ru/checkout/checkout_1550065806744526_228300388_b89VYXLhPSeBONp9V6Gm">цим
    посиланням</a>.

    <p><b>Якщо в Антології знайшлася помилка</b></p>

    Якщо ви побачили граматичну, стилістичну або фактологічну помилку, напишіть про це на адресу
    voxcheck@gmail.com. Можна прикласти скрін цитати та перевірки до неї або скопіювати текст (Ctrl+C).
    Обов’язково вкажіть у вашому листі, в чому полягає помилка, і після перегляду фактчеку редколегією
    проекту VoxCheck вона буде виправлена на сайті.

    <p><i>* Станом на лютий 2019 року цю базу можна було поширити тільки повністю. Сподіваємося, за деякий
      час ми зробимо можливим поширювати окремі висловлювання конкретних політиків. Слідкуйте за
      оновленнями на нашому <a href={telegramLink}>Телеграм-каналі</a>.</i></p>

  </Wrapper$>
);
