import React from "react";
import styled from "styled-components";
import { extraCl, falseCl, manipCl, trueCl } from "./constants";
import { media } from "./helpers";

const Wrapper$ = styled.div`
    margin: 40px 0;
   .true {
    color: ${trueCl}
    }
    .extra {
    color: ${extraCl}
    }
    .manip {
    color: ${manipCl}
    }
    .false {
    color: ${falseCl}
    }
    ${media.verticalPhone`
      padding: 0 20px;
    `};
    a
`;
export default () => (
  <Wrapper$>
    "Антологія брехні" була викладена у відкритий доступ у лютому 2019 року. Тоді на сайті з’явилися 1952 твердження
    десяти топ-політиків/лідерів соціологічних опитувань, які увійшли до <a target="blank"
                                                                            href="https://voxukraine.org/longreads/lie-2018/index.html">Рейтингу
    маніпуляторів та брехунів</a> 2018 року. Антологія регулярно оновлюється, тому зараз в ній можна знайти
    висловлювання і інших політиків.

    <p><b>Цитати топ-політиків за 2018 рік</b></p>

    Протягом 2018 року проект VoxCheck перевіряв твердження топових українських політиків та складав <a target="blank"
                                                                                                        href="https://voxukraine.org/longreads/lie-2018/index.html">Рейтингу
    маніпуляторів та брехунів</a>. До списку рейтингу потрапили політики та відомі люди, які, згідно з соціологічними
    опитуваннями, мали особистий або партійний рейтинг більше 3,5%. Такий вибір показника пов’язаний з тим, що прохідний
    бал до парламенту 5%, але зазвичай соціологічні опитування мають похибку у 1,5-2%. Також до Рейтингу увійшли лідери
    держави - Президент та Прем’єр-міністр.

    <p>Таким чином, у лютому 2019 року на сайті "Антологія брехні" були викладені висловлювання таких персон:</p>

    <ol>
      <li><b>Вадим Рабінович</b>, народний депутат 8-го скликання, голова партії "Опозиційна платформа - За життя"</li>
      <li><b>Юрій Бойко</b>, народний депутат 8 скликання, кандидат у президенти від партії "Опозиційна платформа - За
        життя"
      </li>
      <li><b>Петро Порошенко</b>, Президент України</li>
      <li><b>Володимир Гройсман</b>, Прем’єр-міністр України</li>
      <li><b>Юлія Тимошенко</b>, народний депутат 8 скликання, голова ВО "Батьківщина"</li>
      <li><b>Олег Ляшко</b>, народний депутат 8 скликання, голова "Радикальної партії Олега Ляшка"</li>
      <li><b>Анатолій Гриценко</b>, голова координаційної ради партії "Громадянська позиція"</li>
      <li><b>Володимир Зеленський</b>, співвласник студії "Квартал-95", кандидат у президенти від партії "Слуга народу"
      </li>
      <li><b>Андрій Садовий</b>, мер Львова, голова партії "Самопоміч"</li>
      <li><b>Олег Тягнибок</b>, голова ВО "Свобода"</li>
    </ol>

    До вибірки тверджень цих політиків увійшли висловлювання в основному з усних виступів під час важливих заходів.
    Наприклад, Погоджувальна Рада, засідання або брифінг у Верховній Раді, конференція, тощо, а також інтерв’ю, виступів
    на телеканалах (<a target="blank"
                       href="http://tampanel.com.ua/rubrics/canals/">ТОП-30</a>) або інтерв’ю у центральних онлайн-ЗМІ
    (Українська Правда, Ліга.net, Цензор, Новое время, Дзеркало тижня тощо). Акцент на усних виступах та інтерв’ю був
    зроблений, тому що набагато важливіше побачити, наскільки сам політик орієнтується в тих чи інших речах, ніж
    перевірити цифри або факти, написані його піарниками на Facebook, Twitter або у прес-релізах. Ми також виключили з
    перевірки регіональні медіа, адже, за нашими спостереженнями, тези, які політики озвучують на центральних каналах,
    повторюються ними в регіональних медіа.
    <p><b>Цитати топ-політиків за інші роки, окрім 2018, а також цитати інших політиків</b></p>
    Основний масив цитат Антології припадає на ті перевірки, що увійшли в <a target="blank"
                                                                             href="https://voxukraine.org/longreads/lie-2018/index.html">Рейтингу
    маніпуляторів та брехунів за 2018 рік</a>. Але Антологія постійно поповнюється новими цитатами політиків - як
    топових, так і маловідомих, а також чиновників та державних управлінців.
    <p>Методологія відбору цитат топових політиків у 2018 році вказана вище. Щодо решти політиків (або цитат топових
      політиків до 2018 року або після), то правила відбору тверджень менш жорсткі. Наприклад, ми могли перевірити
      Facebook-допис одного з рейтингових або нерейтингових політиків та викласти цей фактчек на сайті Антології. Але
      щодо
      усіх фактчеків діють загальні правила (див. нижче).</p>
    <b>Загальні положення Методології</b>
    <p><b>Висловлювання для фактчеку мають містити статистику, дані та твердження, які можна перевірити за допомогою
      відкритих джерел, законів ринкової економіки та здорового глузду</b>. Ми не перевіряємо конфіденційні дані, яких
      немає у відкритому доступі. Також VoxCheck не перевіряє думки, які не базуються на конкретних фактах. Цитати,
      вирвані з контексту, перевірці не підлягають. Якщо таке сталося, то перевірка має бути переглянута.</p>
    Кожне перевірене твердження отримало один з вердиктів VoxCheck:

    <p><span className="true">Правда</span>: інформація правдива, дані викладені у коректному контексті. Наведені дані
      можуть
      відрізнятися від правильних на 0-10%</p>
    <p><span className="extra">Перебільшення</span>: Інформація правдива, але дані перебільшені на 10,-20% від
      правильних. Ідея правильна
      або перебільшена.</p>
    <p><span className="manip"> Маніпуляція</span>: Інформація або дані правдиві, але подані у спотвореному контексті
      або
      не повністю.
      Спікер намагається проілюструвати хибну ідею</p>
    <p><span className="false"> Неправда</span>: Інформація неправдива або наведені дані не відповідають дійсності.
      Також
      дані є перебільшеними більш ніж на 20% від коректних</p>
    Слова або фрази у висловлюваннях політиків в Антології брехні, наведені в дужках, є приміткою редактора для
    пояснення, що автор висловлювання мав на увазі.
  </Wrapper$>
);
