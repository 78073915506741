import React from "react";
import styled from "styled-components";
import { evalColorMap, gradientColorMap } from "../constants";

const Wrapper$ = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 50px 0 100px;
`;

const ElementWrapper$ = styled.div`
  background-color: #f0f0f0;
  margin: 5px 0;
  height: 6px;
  position: relative;
  border-radius: 0 4px 4px 0;
`;
const Element$ = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => props.height || 6}px;
  width: ${props => (props.width ? `${props.width}%` : "30px")};
  background: linear-gradient(${p => p.color});
  border-radius: 0 4px 4px 0;
`;
const Title$ = styled.div`
  color: ${p => p.color};
  font-size: 10px;
  position: absolute;
  right: 100%;
  bottom: -3px;
  margin-right: 5px;
  text-transform: uppercase;
`;

const Percents$ = styled.div`
  color: ${p => p.color};
  font-size: 10px;
  position: absolute;
  left: 100%;
  bottom: -3px;
  margin-left: 20px;
  font-weight: 500;
`;

export const Barchart = ({ data }) => {
  return (
    <Wrapper$>
      {data.map((d, i) => (
        <ElementWrapper$ key={d.title}>
          <Title$ color={evalColorMap[d.title]}>{d.title}</Title$>
          <Element$
            index={i}
            width={d.value}
            color={gradientColorMap[d.title]}
          />
          <Percents$ color={evalColorMap[d.title]}>{d.value}%</Percents$>
        </ElementWrapper$>
      ))}
    </Wrapper$>
  );
};
