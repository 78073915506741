import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import PieChart from "react-minimal-pie-chart";

const PhotoPieWrapper$ = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;  
  height: ${props => props.size || 100}px;
  width: ${props => props.size || 100}px;
`;

const Img$ = styled.img`
  left: 50%;
  height: ${props => props.size || 80}px;
  width: ${props => props.size || 80}px;
  background-color: white;
  object-fit: cover;
  border-radius: 50%;
  -webkit-filter: ${p => p.isActive ? "none" : "grayscale(100%)"};
  filter: ${p => p.isActive ? "none" : "grayscale(100%)"};
  
  &:after {
    content: attr(alt);  
    display: flex;
    font-size: 12px;
    font-style: italic;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }
`;

const ImgPlaceholder$ = styled(Img$)`
  background: url(154.jpg) no-repeat center;
  background-size: contain;
  height: ${props => props.size || 80}px;
  width: ${props => props.size || 80}px;
`;

const PieChart$ = styled(PieChart)`
  position: absolute;
  
  svg {
    position: relative;
    z-index: 5;
  }
`;

const defaultPieData = [{
  title: "Нема даних",
  value: 100,
  order: 1,
  color: "#eee",
}];

export const PhotoPie = ({ imgSrc, pieData, size, id, isActive, alt="" }) => {
  return (
    <PhotoPieWrapper$ size={size} data-tip data-for={id}>
      <PieChart$
        data={pieData.length ? pieData : defaultPieData}
        lineWidth={0.09 * size}
        paddingAngle={2}
        startAngle={-90}
      />
      {imgSrc ? (
        <Img$ isActive={isActive} src={imgSrc} size={0.86 * size} alt={alt}/>
      ) : (
        <ImgPlaceholder$ size={size - 30}/>
      )}
    </PhotoPieWrapper$>
  );
};

PhotoPie.propTypes = {
  pieData: PropTypes.array.isRequired
};
