import React from "react";
import styled from "styled-components";
import { PersonsCard, CommonCard } from "./PersonsCard";

const Wrapper$ = styled.div`
  max-width: 100%;
`;

export const SelectedPersonsList = ({
                                      noActivePersons,
                                      personFilter,
                                      personsDict,
                                      commonStatistics,
                                      setFilters
                                    }) => (
  <Wrapper$>
    {!noActivePersons ? (
      Object.keys(personFilter).map(
        id =>
          personFilter[id] ? (
            <div key={id}>
              <PersonsCard key={id} item={personsDict[id]} setFilters={setFilters}/>
            </div>
          ) : null
      )
    ) : <CommonCard commonStatistics={commonStatistics}/>
    }
    {/*<Waypoint*/}
    {/*onEnter={() => setFiltersFixed(false)}*/}
    {/*onLeave={() => setFiltersFixed(true)}*/}
    {/*/>*/}
  </Wrapper$>
);
