import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getQuotesAction,
  initialSearchAction,
  searchAction,
  setFilterFixed,
  setFilters,
  setPagination
} from "../store/actions";
import styled from "styled-components";
import { QuotesList } from "./QuotesList.jsx";
import { PhotoPiesList } from "./PhotoPieList";
import { SelectedPersonsList } from "./SelectedPersonsList";
import { QuotesFilters, Tag$ } from "./QuotesFilters";
import { getQuery, getTrueKeys, media, stringifyQuery } from "../helpers";
import { screenSizes } from "../constants";
import { TagsList } from "./TagsList";

const Wrapper$ = styled.div`
  display: flex;
  justify-content: left;
  flex-grow: 1;
  flex-direction: column;
  margin-left: 392px;
  
  ${media.desktop`
    margin: 0 auto;
  `};
`;

const Card$ = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  // min-width: 300px;
`;

const PolitMobileBttn$ = styled(Card$)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;  
  ${p => p.expanded && `
    img {
      transform: rotate(180deg)
    }
  `}
`;

const PoliticsWrapper$ = styled(Card$)`
    position: absolute;
    width: 300px;
    left: 0;
    top: 0;
    width: 362px;
    z-index: 10;
    text-align: center;
    padding: 15px;
    overflow: auto;

  ${media.desktop`
    position: static;
    //max-height: 450px;
    width: inherit;
    // overflow: auto;
  `};
`;

const Intro$ = styled.div`
  padding: 10px 0;
  h4 {
    margin-bottom: 0
  }
  ${media.verticalPhone`
    padding: 10px 20px;
  `};
`;

const SearchWrapper$ = styled.div`
  margin-bottom: 10px;
  
  input {
    width: 100%;
    height: 22px;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 0 5px;
    
    ${media.tablet`
      height: 40px;
      font-size: 16px;
    `};
    ${media.tablet`
      font-size: 16px;
    `};
    
    
  }
`;

class MainPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPersonsExpanded: false,
      isTopicExpanded: false
    };
  }

  componentDidMount() {
    const { setFilters, location } = this.props;
    if (location.search.length > 3) {
      setFilters({key: "bulk", value: getQuery(location.search)})
    }
    this.props.initialSearchAction();
    this.props.getQuotesAction();

  }

   componentDidUpdate(prevProps, prevState, snapshot) {
     const { history, location, filters} = this.props;
     if (filters !== prevProps.filters) {
       // add current filters in the url search query
       const queryParams = getQuery(location.search);
       ['person', 'eval', 'topic'].forEach(key => {
         queryParams[key] = getTrueKeys(filters[`${key}Filter`])
       });
       const newQueryStr = stringifyQuery(queryParams);
       if (newQueryStr !== location.search) {
         history.push({
           search: newQueryStr,
         });
       }
     }
   }

  _onPoliticsClick = (value) => {
    this.props.setFilters({ key: "personFilter", value })
  };

  renderPoliticsList = ({ persons, filters, setFilters, isMobile, screenWidth }) => persons && persons.length && (
    <PoliticsWrapper$>
      <h4>Який політик вас цікавить?</h4>
      <PhotoPiesList
        data={persons}
        size={128}
        clickClb={this._onPoliticsClick}
        activePersons={filters.personFilter}
        screenWidth={screenWidth}
      />
    </PoliticsWrapper$>
  );

  render() {
    if (!this.props.metaInfo.topicsList.length) {
      return <h2>Антологія Брехні</h2>
    }
    const {
      metaInfo: { commonStatistics, personsDict, topicsList, lastUpdate, baseSize },
      persons,
      filters = {},
      quotes,
      setFilters,
      setPagination,
      setFilterFixed,
      uiElements: { pagination, fixedFilters }
    } = this.props;
    const screenWidth = document.body.clientWidth;
    const isMobile = screenWidth < screenSizes.phone;
    const choosedTopics = Object.values(filters.topicFilter).filter(d => d).length;

    if (isMobile && this.state.isExpanded) {
      return (
        <React.Fragment>
          <PolitMobileBttn$ onClick={() => this.setState({ isExpanded: !this.state.isExpanded })}>
            <span>Який політик вас цікавить?</span>
            <span><img src='icons/arrow-black-left.svg'/></span>
          </PolitMobileBttn$>
          <p>Oберіть одного або кількох політиків, які вас цікавлять</p>
          <Card$>
            <PhotoPiesList
              data={persons}
              size={128}
              clickClb={value => setFilters({ key: "personFilter", value })}
              activePersons={filters.personFilter}
              screenWidth={screenWidth}
            />
          </Card$>
        </React.Fragment>
      );
    }

    if (isMobile && this.state.isTopicExpanded) {
      return (
        <React.Fragment>
          <PolitMobileBttn$ onClick={() => this.setState({ isTopicExpanded: !this.state.isTopicExpanded })}>
            <span>Тема</span>
            <span><img src='icons/arrow-black-left.svg'/></span>
          </PolitMobileBttn$>
          <p>Oберіть тему</p>
          <Card$>
            <TagsList
              setFilters={setFilters}
              tags={topicsList}
              topicFilter={filters.topicFilter}
            />
          </Card$>
        </React.Fragment>
      );
    }

    return (
      <Wrapper$>
        <Intro$>
          <h1>Антологія Брехні</h1>
          База даних проекту VoxCheck, яка постійно оновлюється. Зараз тут можна
          переглянути <b>{baseSize}</b> твердження українських
          політиків та перевірки до них.
          {lastUpdate && <h4>Базу оновлено {lastUpdate}</h4>}
        </Intro$>
        <SearchWrapper$>
          <input
            autoFocus={!isMobile}
            placeholder="Пошук по цитатах"
            onChange={e => setFilters({ key: "searchFilter", value: e.target.value })}
            value={filters.searchFilter || ""}
          />
        </SearchWrapper$>
        <div>
          {isMobile ? (
              <PolitMobileBttn$ onClick={() => this.setState({ isExpanded: !this.state.isExpanded })} expanded>
                <span>Який політик вас цікавить?</span>
                <img src='icons/arrow-black-left.svg'/>
              </PolitMobileBttn$>)
            : this.renderPoliticsList({ ...this.props, screenWidth, isMobile })
          }
          {isMobile && (
            <PolitMobileBttn$ onClick={() => this.setState({ isTopicExpanded: !this.state.isTopicExpanded })} expanded>
              <span>{choosedTopics > 0 ? `Обраних тем: ${choosedTopics}` : "Оберіть тему"}</span>
              <img src='icons/arrow-black-left.svg'/>
            </PolitMobileBttn$>)
          }
          <QuotesFilters
            topicsList={topicsList}
            filters={filters}
            persons={persons}
            setFilters={setFilters}
            isFixed={fixedFilters}
            isMobile={isMobile}
          />
          <SelectedPersonsList
            noActivePersons={filters.noActivePersons}
            personFilter={filters.personFilter}
            personsDict={personsDict}
            commonStatistics={commonStatistics}
            setFilters={setFilters}
          />
          <QuotesList
            filteredQuotes={quotes.filteredQuotes}
            personsDict={personsDict}
            setPagination={setPagination}
            pagination={pagination}
            isFixed={fixedFilters}
            isMobile={isMobile}
          />
        </div>
      </Wrapper$>
    );
  }
}

const mapStateToProps = ({
                           uiElements,
                           metaInfo,
                           persons,
                           quotes,
                           filters
                         }) => ({
  uiElements,
  metaInfo,
  persons,
  quotes,
  filters
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      searchAction,
      initialSearchAction,
      getQuotesAction,
      setFilters,
      setPagination,
      setFilterFixed
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainPage);
