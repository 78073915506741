import { combineReducers } from "redux";
import { SET_FIXED_FILTERS, SET_META_INFO, SET_PAGINATION, SET_QUOTES, SET_TOPIC_FILTER } from "./types";
import { mapToArr, parseTrueKeys, sortPersonsByEval } from "../helpers";

import Papa from "papaparse";

function persons(state = [], action) {
  switch (action.type) {
    case SET_META_INFO:
      return sortPersonsByEval(mapToArr(action.payload.personsDict));
    default:
      return state;
  }
}

const defaultFilters = {
  personFilter: {},
  noActivePersons: true,
  topicFilter: {},
  evalFilter: {},
  searchFilter: "",
  isFiltered: false
};

function filters(state = defaultFilters, action) {
  const { payload, type } = action;
  switch (type) {
    case SET_META_INFO:
      const { topicsLabelsList } = action.payload;
      return {
        ...state,
        topicsLabelsList,
      };
    case SET_TOPIC_FILTER:
      return getFilters(state, payload);
    default:
      return state;
  }
}

const DEFAULT_QUOTES_STATE = {
  allQuotes: [],
  filteredQuotes: [],
  filters: defaultFilters
};

function quotes(state = DEFAULT_QUOTES_STATE, action) {
  switch (action.type) {
    case SET_META_INFO:
      const { startQuotes, validOptions } = action.payload;
      return {
        ...state,
        allQuotes: startQuotes,
        filteredQuotes: startQuotes,
        validOptions,
      };
    case SET_QUOTES:
      try {
        const parsed = Papa.parse(action.payload, { header: true, delimiter: "," });
        if (!parsed || !parsed.data || !parsed.data.length) return state;
        const dataRevert = (str) => {
          if (!str || !str.length || !str.split(".").length) return "";
          let arr = str.split(".");
          return [arr[1], arr[0], arr[2]].join(".");
        };
        const data = parsed.data.sort((a, b) => new Date(dataRevert(b.quote_date)) - new Date(dataRevert(a.quote_date)));
        return {
          ...state,
          allQuotes: data,
          filteredQuotes: filterQuotes(data, state.filters)
        };
      } catch (e) {
        console.error('SET_QUOTES - Reducer parsing Error');
        return state;
      }

    case SET_TOPIC_FILTER:
      const currentFilters = getPrevFilters(state.filters, action.payload, state.validOptions);
      return {
        ...state,
        filters: currentFilters,
        filteredQuotes: filterQuotes(
          state.allQuotes,
          currentFilters
        )
      };
    default:
      return state;
  }
}

// function evalByTopics(state = {}, action) {
//   switch (action.type) {
//     case SET_EVAL_BY_TOPICS:
//       return action.payload;
//     default:
//       return state;
//   }
// }

const DEFAULT_UI_STATE = {
  pagination: 1,
  fixedFilters: false
};

function uiElements(state = DEFAULT_UI_STATE, action) {
  switch (action.type) {
    case SET_PAGINATION:
      return { ...state, pagination: action.payload.selected + 1 };
    case SET_TOPIC_FILTER:
      return { ...state, pagination: DEFAULT_UI_STATE.pagination };
    case SET_FIXED_FILTERS:
      return { ...state, fixedFilters: action.payload };
    default:
      return state;
  }
}

const DEFAULT_COMMON_STATE = {
  personsDict: {},
  topicsList: [],
  topicsLabelsList: [],
  commonStatistics: {
    evaluation: [],
    connotation: [],
    topTopics: {}
  },
  topLiers: []
};

function metaInfo(state = DEFAULT_COMMON_STATE, action) {
  switch (action.type) {
    case SET_META_INFO:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

const reducer = combineReducers({
  persons,
  uiElements,
  metaInfo,
  quotes,
  filters
});

export default reducer;

//Helpers
function getFilters(...arg) {
  const filters = getPrevFilters(...arg);
  const { personFilter={}, evalFilter = {}, topicFilter={}, searchFilter=[] } = filters;
  const noActivePersons = !Object.values(personFilter).some(a => a);
  filters.noActivePersons = noActivePersons;
  filters.isFiltered = Object.values(evalFilter).some(a => a)
    || Object.values(topicFilter).some(a => a)
    || searchFilter.length > 0;
  return filters;
}

function getPrevFilters(state, payload, validOptions) {
  if (payload.key === "clearAll") return { ...defaultFilters, personFilter: state.personFilter } ;
  if (payload.key === "bulk" ) {
    // calls only once when app is loaded from the string, so filters state is empty
    const newFiltersState = Object.keys(payload.value).reduce((res, key) => {
      // if (key === 'topic' && state.topicsLabelsList.indexOf())
      res[`${key}Filter`] = parseTrueKeys(payload.value[key], (validOptions || {})[key]);
      return res
    }, {});
    return { ...defaultFilters, ...newFiltersState };
  }
  if (payload.key === "personFilter") {
    let personFilter;
    if (payload.isPersonsSelect) {
      personFilter = {};
      payload.value.forEach(p => (personFilter[p.value] = true));
    } else {
      personFilter = {
        ...state.personFilter,
        [payload.value.id]: payload.value.isActive
      };
    }
    return {
      ...state,
      personFilter,
      noActivePersons: !Object.values(personFilter).some(a => a)
    };
  }
  if (payload.key === "topicFilter") {
    let newTopicFilter = { ...state.topicFilter };
    newTopicFilter[payload.title] = payload.value;
    return {
      ...state,
      topicFilter: newTopicFilter
    };
  }
  if (payload.key === "evalFilter") {
    let newEvalFilter = { ...state.evalFilter };
    newEvalFilter[payload.title] = payload.value;
    return {
      ...state,
      evalFilter: newEvalFilter
    };
  }
  return {
    ...state,
    [payload.key]: payload.value
  };
}

function filterQuotes(allQuotes, currentFilters) {
  const {
    topicFilter = {},
    searchFilter,
    personFilter,
    evalFilter = {},
    noActivePersons
  } = currentFilters;
  const topicsValues = Object.values(topicFilter);
  const evalValues = Object.values(evalFilter);
  const passFilters = quote => {
    const matchTopic = topicsValues.some(d => d)
      ? (topicFilter[quote.topic1] || topicFilter[quote.topic2])
      : true;
    const matchEval = evalValues.some(d => d)
      ? evalFilter[quote.evaluation]
      : true;
    const matchPerson = noActivePersons || personFilter[quote.person_id];
    const matchSearch = searchFilter
      ? (~quote.quote.toLowerCase().indexOf(searchFilter.toLowerCase())
        //|| comment?
      )
      : true;
    return matchSearch && matchTopic && matchPerson && matchEval;
  };
  return allQuotes.filter(a => passFilters(a));
}
