import React from "react";
import styled from "styled-components";
import { media } from "../helpers";

const Wrapper$ = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Tag$ = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #444;
  cursor: pointer;
  margin: 4px;
  border: 1px solid #F0F0F0;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0 5px;
  height: 23px;
  ${p => p.isActive && `
    background-color: #F0F0F0;
  `}

  &:hover {
    border: 1px solid #bbb;
  }
  
  ${media.phone`
    height: 40px
  `};
`;

export const TagsList = ({ tags, setFilters, topicFilter }) => (
  <Wrapper$>
    {tags.map(
      t =>
        !t.title ? null : (
          <Tag$
            key={t.title}
            onClick={() =>
              setFilters({
                key: "topicFilter",
                title: t.title,
                value: !topicFilter[t.title]
              })
            }
            isActive={topicFilter[t.title]}
          >
            {t.title}
          </Tag$>
        )
    )}
  </Wrapper$>
);
