export const apiEndpoint = `https://52m00xil3d.execute-api.eu-central-1.amazonaws.com/beta`;
export const liqPayUrl = "https://www.liqpay.ua/api/3/checkout?data=eyJ2ZXJzaW9uIjozLCJhY3Rpb24iOiJwYXlkb25hdGUiLCJwdWJsaWNfa2V5IjoiaTU1NDYwNjQ0NzM4IiwiYW1vdW50IjoiMTAwMCIsImN1cnJlbmN5IjoiVUFIIiwiZGVzY3JpcHRpb24iOiLQkdC70LDQs9C+0LTRltC50L3QuNC5INCy0L3QtdGB0L7QuiDQvdCwINC/0YDQvtC10LrRgiDQv9C10YDQtdCy0ZbRgNC60Lgg0L/QvtC70ZbRgtC40LrRltCyIFZveENoZWNrIiwidHlwZSI6ImRvbmF0ZSIsImxhbmd1YWdlIjoicnUifQ==&signature=FrUjFp/JwjRJLSVTjID2J+YMW4k=";
const rootUrl = 'https://nepravda.org';
export const fbShareLink = 'https://www.facebook.com/sharer/sharer.php?u=' + rootUrl + '&amp;src=sdkpreparse';
export const telegramLink = 'https://t.me/voxcheck';
export const fbLink = 'https://www.facebook.com/VoxCheck';
export const googleAnalyticsId = 'UA-134714250-1';
export const linksColor = '#2395FF';
export const screenSizes = {
  desktop: 992,
  tablet: 768,
  phone: 576,
  verticalPhone: 320
};
export const quotesInScreen = 20;
export const green = '#74AF49';
export const red = '#FF004D';
export const orange = '#F48758';
export const yellow = '#F9C763';
//"#6b9080", "#92dce5", "#639a88", "#58a4b0", "#a4c3b2"
export const falseCl = '#FF004D'; // "#000";
export const manipCl = '#935BCD'; // "#363435";
export const extraCl = '#FFBC3F'; //"#5d5d5d";
export const trueCl = '#76CE38'; //"#0024ff";

/** COLORS */
export const primaryColor = '#af4f09';
export const blackColor = '#333';
export const dividerColor = '#cccccc';
export const stackColors = [falseCl, manipCl, extraCl, trueCl]; //[red, orange, yellow, green];
export const evalColorMap = {
  Неправда: falseCl,
  Маніпуляція: manipCl,
  Перебільшення: extraCl,
  Правда: trueCl //green
};

export const gradientColorMap = {
  Неправда: '90deg, #FD145A 0%, #FE5038 104.67%',
  Маніпуляція: '270deg, #BB6BCE 0%, #935BCD 98.16%',
  Перебільшення: '270deg, #FFF974 -3.68%, #FFBC3F 100%',
  Правда: '90deg, #76CE38 0%, #D9EC38 94%' //green
};

export const emotionalColorMap = {
  Позитив: trueCl,
  Нейтрал: extraCl,
  Негатив: falseCl
};

export const evalIndexesMap = {
  Неправда: 0,
  Маніпуляція: 1,
  Перебільшення: 2,
  'Технічна помилка': 3,
  Правда: 4,
  'Без вердикту': 5
};

export const evalList = ['Правда', 'Перебільшення', 'Маніпуляція', 'Неправда'];
