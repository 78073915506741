import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import get from 'lodash/get';
import { evalColorMap, trueCl } from "../constants";
import { media } from "../helpers";
import { PhotoPie } from "./PhotoPie";

const PieBlockWrapper$ = styled.div`
  cursor: pointer;
`;

const ChartsWrapper$ = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  flex-wrap: wrap;
  // overflow: auto; 
  
  ${media.tablet`
     max-height: 300px;
 `};
 
 ${media.phone`
     max-height: inherit;
 `};

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 5px 50px 5px;
    position: relative;
    ${media.verticalPhone`
      width: 100%;
    `};
  }
`;

const Name$ = styled.div`
  position: absolute;
  font-weight: bold;
  font-size: 12px;
  top: 130px;
  text-align: center;
  padding-bottom: 5px;
  ${p => p.isActive && `border-bottom: 2px solid ${trueCl};`} margin: 5px;
`;

const LineBreak$ = styled.div`
  width: 100%;
  height: 0;
  margin: 0 !important;
`;

export const PhotoPiesList = ({ data, size, clickClb, activePersons = {} }) => (
  <ChartsWrapper$>
    {data.map((person, i) => (
      <React.Fragment key={person.person_id}>
        <PieBlockWrapper$
          onClick={() => {
            if (get(person, 'statistics.evaluation')) {
              clickClb({
                id: person.person_id,
                isActive: !activePersons[person.person_id]
              })
            }
            return;
          }}
        >
          <PhotoPie
            pieData={get(person, 'statistics.evaluation', []).map(item => ({
              ...item,
              color: evalColorMap[item.title]
            }))}
            imgSrc={`photo/${person.person_id}.jpg`}
            size={size || 150}
            id={`pie-${person.person_id}`}
            isActive={activePersons[person.person_id]}
            alt={person.name}
          />
          <Name$>{person.name}</Name$>
        </PieBlockWrapper$>
        {/*{getLineBreakCase(i) && <LineBreak$/>}*/}
      </React.Fragment>
    ))}
  </ChartsWrapper$>
);

PhotoPiesList.propTypes = {
  data: PropTypes.array.isRequired,
  clickClb: PropTypes.func.isRequired
};

function getLineBreakCase(i) {
  const width = document.body.clientWidth;
  if (width < 540 || width > 768) return false;
  const m = width > 622 ? 5 : 4;
  return (i + 1) % m === 0;
}
