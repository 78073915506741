import axios from "axios";

let cacheConfig = {
  headers: {
    "Cache-Control": "public, max-age=86400"
  }
};

function getQuotes(keyword) {
  // return axios.get(`${apiEndpoint}/search?keyword=${keyword}`);
  return axios.get("data/quotes.csv", cacheConfig);
}

function initialRequest() {
  //return axios.get(`${apiEndpoint}/quotes`).then(response => {
  return axios.get(`data/initialInfo.json`, cacheConfig);
}

function evalByTopicRequest() {
  //return axios.get(`${apiEndpoint}/quotes`).then(response => {
  return axios.get(`data/evalByTopics.json`, cacheConfig);
}

export default {
  getQuotes,
  initialRequest,
  evalByTopicRequest
};
