import React from 'react';
import styled from 'styled-components';
import { fbShareLink, fbLink, blackColor, liqPayUrl, telegramLink } from "../constants";
import { Link } from 'react-router-dom';
import { media } from "../helpers";
import { HeaderMenu } from "./HeaderMenu";

const FlexRow$ = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: ${p => p.mw || 'inherit'};
  flex-grow: 1;
`;

const linkStyles = `
  color: ${blackColor};
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  
  &:visited {
    color: ${blackColor}
  }`
;

const Wrapper$ = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 80px;
  color: ${blackColor};
  
  ${media.phone`
    display: none;
  `};
  
  a {
    ${linkStyles}
  }
`;

const WrapperMobile$ = styled(Wrapper$)`
  display: none;
  ${media.phone`
      display: flex;
  `};
  
  a {
    ${linkStyles}
  }
  ${media.verticalPhone`
    padding: 0 20px;
  `};
`;

const Logo$ = styled.div`
  // font-size: 20px;
  // font-weight: bold;
`;

const Link$ = styled(Link)`
  ${linkStyles}
  margin: 0 7px;
  ${media.tablet`
    &.home {
      display: none;
    }
  `};
  ${media.phone`
    &.home {
      display: block;
    }
  `};
`;

const DownloadBtn$ = styled.a`
  background-color: #4D4D4D;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  height: 22px;
  width:  137px;
  border-radius: 3px;
  border: 1px solid #F0F0F0;
  font-size: 10px;
  color: white !important;
  text-transform: uppercase;
  padding: 0 5px;
  text-decoration: none;
  
  &:visited {
    color: white !important;
  }
  ${media.tablet`
      width:  auto;
      min-width: 110px;
     img {
      display: none;
     }
  `};
  
  ${media.phone`
      display: flex !important;
      min-width: inherit;
      max-width: inherit;
      height: 30px;    
  `};
`;

const FbBtn$ = styled(DownloadBtn$)`
  background-color: #4267b2;
  width: 90px;
  ${media.tablet`
      max-width:  100px;
      min-width:  80px;
     img {
      display: inline-block;
     }
  `};
  ${media.phone`
      display: flex !important;
      min-width: inherit;
      max-width: inherit;      
  `};
`;

export const Header = () => {
  const items = (
    <React.Fragment>
      <Link$ className="home" to="/">Цитати</Link$>
      <Link$ to="/about">Хто ми</Link$>
      <Link$ to="/methodology">Методологія</Link$>
      <a target="blank" href={liqPayUrl}>Підтримати проект</a>
    </React.Fragment>
  );
  const buttons = (
    <React.Fragment>
      <DownloadBtn$ className="btn" href="/data/download.xls" download>
        <img src='icons/download.svg'/>
        Завантажити базу
      </DownloadBtn$>
      <FbBtn$ className="btn fb-xfbml-parse-ignore fb-link" target="_blank" href={fbShareLink}>
        <img src='icons/fbSign.svg'/>
        Поділитись
      </FbBtn$>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <WrapperMobile$>
        <HeaderMenu
          items={items}
          buttons={buttons}
        />
        <Logo$><Link$ to="/"><img width='94px' src='icons/voxLogo.svg'/></Link$></Logo$>
        <FlexRow$ mw={'60px'}>
          <a target='blank' href={fbLink}><img src='icons/fb.svg'/></a>
          <a target='blank' href={telegramLink}><img src='icons/telegram.svg'/></a>
        </FlexRow$>
      </WrapperMobile$>
      <Wrapper$>
        <Logo$><Link$ to="/"><img width="92" height="92" src='icons/voxLogo.svg'/></Link$></Logo$>
        {buttons}
        <FlexRow$ mw={'400px'}>
          {items}
          <FlexRow$ mw={'60px'}>
            <a target='blank' href={fbLink}><img src='icons/fb.svg'/></a>
            <a target='blank' href={telegramLink}><img src='icons/telegram.svg'/></a>
          </FlexRow$>
        </FlexRow$>
      </Wrapper$>
    </React.Fragment>
  );
};
