import React from "react";
import styled from "styled-components";
import { evalColorMap } from "../constants";
import { media } from "../helpers";
import { TagsList } from "./TagsList";

const FiltersWrapper$ = styled.div`
  position: relative;
  padding: 15px;
  background: #FFFFFF;
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;
const FlexWrapper$ = styled.div`
  display: flex;
  justify-content: ${p => p.space || 'space-between'};
  flex-wrap: wrap;
  flex-grow: 1;
`;

const TagsWrapper$ = styled.div`
  ${media.phone`
    display: none;
  `};
`;

const controlsSize = `
  height: 30px;
  border-radius: 5px;
  background-color: #fff;
`;

const ResetBtn = styled.div`
  position: absolute;
  top: 5px;
  right: 15px;
  z-index: 20;
  padding: 10px;
  cursor: ${p => p.isFiltered ? 'pointer' : 'default'};
  opacity: ${p => p.isFiltered ? 1 : 0.2};
  ${media.phone`
    right: -5px;
    top: -5px;
  `};
`;

const EvalWrapper$ = styled(FlexWrapper$)`
  justify-content: space-around;
  flex-wrap: wrap;
`;

const EvalLabel$ = styled.div`
  font-size: 10px;
  max-width: 140px;
  min-width: 100px;
  width: 25%;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  border-radius: 3px;
  border: 1px solid ${p => p.color};
  text-transform: uppercase;
  margin: 15px 5px 0 5px;
  
  &:hover {
    background-color: ${p => p.color}a1;
  }
  
  ${p =>
    p.isActive
      ? `
    background-color: ${p.color} !important;
    color: white;
  `
      : ''};
  ${media.phone`
    width: 45%;
    height: 40px;
    
    &:hover {
      background-color: inherit;
    }
  `};
`;

export class QuotesFilters extends React.Component {
  render() {
    const {
      topicsList,
      filters: { topicFilter, personFilter, evalFilter, isFiltered },
      setFilters,
      persons,
      isMobile
    } = this.props;
    const personsOptions = persons.map(({ name, person_id }) => ({
      label: name,
      value: person_id
    }));
    const personsSelectedOptions = personsOptions.filter(
      p => personFilter[p.value]
    );
    return (
      <FiltersWrapper$ id="filters">
        <ResetBtn
          isFiltered={isFiltered}
          onClick={e => setFilters({ key: 'clearAll' })}>
          <img src='icons/refresh.svg'/>
        </ResetBtn>
        <TagsWrapper$>
          <h4>Теми</h4>
          <TagsList
            tags={topicsList}
            setFilters={setFilters}
            topicFilter={topicFilter}
          />
        </TagsWrapper$>
        <EvalWrapper$>
          {Object.keys(evalColorMap).map(evln => (
            <EvalLabel$
              key={evln}
              onClick={() => setFilters({ key: 'evalFilter', title: evln, value: !evalFilter[evln] })}
              color={evalColorMap[evln]}
              isActive={evalFilter[evln]}
            >
              <div>{evln}</div>
            </EvalLabel$>
          ))}
        </EvalWrapper$>

      </FiltersWrapper$>
    );
  }
}
